import React from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Landing from "./landing"
import Reports from "./reports"

const App = () => {
  return (
    <Router basename='/'>
      <Routes>
        <Route path="*" element={<Landing />} />
        {/* <Route path="/report" element={<Reports />} /> */}
      </Routes>
    </Router>
  );
}

export default App