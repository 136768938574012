import React, { useState } from 'react';
import './index.css';

const AnimatedInput = ({ type, id, placeholder, onChange, value, name }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(!isFocused);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={`form-floating mb-3 ${isFocused ? 'input-focused' : ''}`}>
      <input
        name={name}
        type={type}
        className="form-control shadow-none"
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
      />
      <label htmlFor={id}>{placeholder}</label>
    </div>
  );
};

export default AnimatedInput;
