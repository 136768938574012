/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

// images
import logo from "./images/logo.png";
import swooshBg from "./images/logo_swoosh_bg.png";
import logoBioHack from "./images/opts/BioHack.png";
import logoHairAlch from "./images/opts/HairAlchemy.png";
import logoMindHack from "./images/opts/MindHack.png";
import logoMomBodyHack from "./images/opts/MomBodyHack.png";
import logoSkinAlch from "./images/opts/SkinAlchemy.png";
import logoNutrition from "./images/opts/foodNutri.png";
import founderGraphic from "./images/founder-graphic.png";

import AnimatedInput from "./input-animated";
import fetchJsonp from "fetch-jsonp";


const initialValue = {
  groups: {
    "5": {
      "1": false,
      "2": false,
      "4": false,
      "8": false,
      "16": false,
      "32": false,
    }
  },
  customerEmail: "",
  customerNumber: "",
  partnerEmail: "",
  partnerPhone: "",
  partnerLinekdin: "",
  partnerSocial: "",
};

const Landing = () => {
  const [formValue, setFormValue] = useState(initialValue);
  const {
    customerEmail,
    customerNumber,
    partnerEmail,
    partnerPhone,
    partnerLinekdin,
    partnerSocial,
  } = formValue;

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("name", name, "value", value);
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleBoxChange = (group, id) => {
    setFormValue(prev => ({
      ...prev,
      groups: {
        ...prev.groups,
        [group]: {
          ...prev.groups[group],
          [id]: !prev.groups[group][id]  // Toggle the group value
        }
      }
    }));
  };

  const validateEmail = (email) => {
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      alert('Please enter a valid email address.');
      return false;
    }
    return true;
  }

  const validatePhone = (phone) => {
    // Phone number validation
    if (!/^\+\d{7,}$/.test(phone)) {
      alert('Please enter a valid phone number in the format +1234567890');
      return false;
    }
    return true;
  }

  const handleSubmit = (event, formType) => {
    event.preventDefault();
    // console.log("formValue", formValue);
    let anyChecked = false; // Variable to track if any checkbox is checked
    const baseUrl = "https://vibetribee.us18.list-manage.com/subscribe/post-json?u=fa4aa7d0150d8868ec4532e9d&id=23be55fb8c";
    let queryParams = new URLSearchParams({});

    if (formType === 1) {

      if (!validateEmail(formValue.partnerEmail) || !validatePhone(formValue.partnerPhone)) return;
      if (!formValue.partnerLinekdin || !formValue.partnerSocial) {
        alert("Please fill in all fields");
        return;
      }
      
      queryParams = new URLSearchParams({
        EMAIL: formValue.partnerEmail,
        PHONE: formValue.partnerPhone,
        MMERGE6: formValue.partnerLinekdin,
        MMERGE8: formValue.partnerSocial,
        MMERGE7: 'Founding Member',
        "c": "callback"
      });

      Object.keys(formValue.groups["5"]).forEach(key => {
        if (formValue.groups["5"][key]) {
          queryParams.append(`group[5][${key}]`, 'checked');
        }
      });

    } else {
      if (!formValue.customerEmail || !formValue.customerNumber) {
        alert("Please fill in all fields");
        return;
      }

      if (!validateEmail(formValue.customerEmail) || !validatePhone(formValue.customerNumber)) return;

      queryParams = new URLSearchParams({
        EMAIL: formValue.customerEmail,
        PHONE: formValue.customerNumber,
        "c": "callback"
      });

      Object.keys(formValue.groups["5"]).forEach(key => {
        if (formValue.groups["5"][key]) {
          queryParams.append(`group[5][${key}]`, 'checked');
          anyChecked = true; // Set anyChecked to true if any checkbox is checked
        }
      });

      // If no checkboxes are checked, alert the user and prevent form submission
      if (!anyChecked) {
        alert("Please select at least one tribe");
        return; // Stop the function from proceeding further
      }

    }

    const url = `${baseUrl}&${queryParams.toString()}`;
    fetchJsonp(url, {
      jsonpCallback: 'c',
      timeout: 5000
    }).then(response => response.json())
      .then(data => {
        if (data.result !== 'success') {
          alert(`Something went wrong. Error: ${data.msg}`);
          console.error('JSONP request failed', data);
          return
        }
        alert('Thankyou for signing up!');
      }).catch(error => {
        alert('Something went wrong, please try again later');
        console.error('JSONP request failed', error);
        return
      });
  };


  const renderCheckbox = (group, id, label, color, logo) => (
    <div
      className={`text-center customCheckbox mb-4 ${formValue.groups[group][id] ? "checked" : ""}`}
      onClick={() => handleBoxChange(group, id)}
    >
      <div className={`customboxer ${color} ${formValue.groups[group][id] ? "checked" : ""}`}>
        <img style={{ width: "168px", height: "250px" }} src={logo} alt={label} />
        <div className="customBoxLabel px-2 py-1 justify-center">{label}</div>
      </div>
    </div>
  );

  const renderCheckboxMobile = (group, id, label, color, logo) => (
    <div
      className={`text-center customCheckbox mb-4 ${formValue.groups[group][id] ? "checked" : ""}`}
      onClick={() => handleBoxChange(group, id)}
    >
      <div style={{ width: "fitContent" }} className={`customboxer ${color} ${formValue.groups[group][id] ? "checked" : ""}`}>
        <img style={{ width: "250px", height: "155px" }} src={logo} />
        <div className="customBoxLabelMobile px-1 py-0 justify-center">{label}</div>
      </div>
    </div>
  );

  return (
    <div className="main-container">
      <section
        data-id="home"
        className="ed-section fullscreen-element sm-pt-180 sm-pb-180 pt-0 pb-0 px-5"
      >
        <div className="container">
          <div className="content">
            <div className="row">
              <div
                className="col-lg-12 col-md-12 text-center py-4"
                data-animation="fadeInUp"
                data-animation-delay="300"
              >
                <img src={logo} height={64} />
              </div>
              <div className="col-lg-12 col-md-12" style={{ padding: "0" }}>
                {/* NEW START */}
                <div className="container-hero">
                  <img className="hero-bg" src={swooshBg} alt="" />
                  <div className="display-5 fw-bold text-center">
                    Learn{" "}
                    <span className="secondary-color">
                      Secrets of Age Reversal
                    </span>{" "}
                    with <br className="linebreak-hero" /> Mentor-Driven
                    Communities
                  </div>

                  <div
                    className="lead text-center mt-2 mx-md-5 px-md-5"
                    style={{ fontSize: "1rem" }}
                    data-animation="fadeInUp"
                    data-animation-delay="600"
                  >
                    Age is just a number, and science backs it! Our courses
                    guide you in slowing the aging process. From not losing your
                    hair to maintaining timeless skin, mid-life weight loss
                    hacks, to regaining your body post motherhood, body biohacks
                    to brain hacks - learn effortlessly with daily bite-sized
                    lessons. Benefit from a supportive community and direct
                    mentor access. Join our vibrant community as we redefine the
                    art of aging gracefully.
                  </div>

                  <div className="h3 fw-bold text-center my-4">
                    Click to Select Your{" "}
                    <span className="primary-color">Interests</span>
                  </div>

                  <div
                    className="text-center desktopView"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {renderCheckbox(
                      "5", "1",
                      "Bio Hacks",
                      "blue",
                      logoBioHack
                    )}
                    {renderCheckbox(
                      "5", "2",
                      "Hair Alchemy",
                      "red",
                      logoHairAlch
                    )}
                    {renderCheckbox(
                      "5", "4",
                      "Mom Body Hacks",
                      "yellow",
                      logoMomBodyHack
                    )}
                    {renderCheckbox(
                      "5", "8",
                      "Mind Hacks",
                      "blue",
                      logoMindHack
                    )}
                    {renderCheckbox(
                      "5", "16",
                      "Skin Alchemy",
                      "yellow",
                      logoSkinAlch
                    )}
                    {renderCheckbox(
                      "5", "32",
                      "Youthful Nutrition",
                      "yellow",
                      logoNutrition
                    )}
                  </div>
                  <div className="mobileView">
                    <div
                      className="text-center pb-2"
                      style={{
                        display: "flex",
                        placeContent: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {renderCheckboxMobile(
                        "5", "1",
                        "Bio Hacks",
                        "blue",
                        logoBioHack
                      )}
                      {renderCheckboxMobile(
                        "5", "2",
                        "Hair Alchemy",
                        "red",
                        logoHairAlch
                      )}
                      {renderCheckboxMobile(
                        "5", "4",
                        "Mom Body Hacks",
                        "yellow",
                        logoMomBodyHack
                      )}
                    </div>

                    <div
                      className="text-center pb-2"
                      style={{
                        display: "flex",
                        placeContent: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {renderCheckboxMobile(
                        "5", "8",
                        "Mind Hacks",
                        "blue",
                        logoMindHack
                      )}
                      {renderCheckboxMobile(
                        "5", "16",
                        "Skin Alchemy",
                        "yellow",
                        logoSkinAlch
                      )}
                      {renderCheckboxMobile(
                        "5", "32",
                        "Youthful Nutrition",
                        "yellow",
                        logoNutrition
                      )}
                    </div>
                  </div>
                </div>

                <hr className="primary-divider my-5" />

                <div className="h1 fw-bold text-center mt-4">
                  Be the first to know & Get{" "}
                  <span className="primary-color">Special Launch Offers</span>
                </div>

                {/* SignUp Form */}
                <div className="row text-center px-lg-5 mx-lg-5 mt-5">
                  {/* Email Input */}
                  <div className="col-md-6 col-sm-6">
                    <AnimatedInput
                      name="customerEmail"
                      type="email"
                      id="email-signup"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      value={customerEmail}
                    />
                  </div>
                  {/* Phone Input */}
                  <div className="col-md-6 col-sm-6">
                    <AnimatedInput
                      name="customerNumber"
                      type="tel"
                      id="phone-signup"
                      placeholder="Enter your phone number"
                      onChange={handleChange}
                      value={customerNumber}
                    />
                  </div>
                </div>
                <div className="row text-center" style={{ padding: "20px 0" }}>
                  <div className="col-md-12 text-center">
                    <div className="d-grid gap-2 col-sm-5 col-md-3 col-xs-8 mx-auto">
                      <button
                        className="btn btn-primary fw-bold"
                        type="submit"
                        onClick={(e) => handleSubmit(e, 0)}
                      >
                        Get Pre-Launch Offer
                      </button>
                    </div>
                  </div>
                </div>

                <hr className="primary-divider my-5" />

                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      {/* TODO: make font softer */}
                      <div className="softer-font h5 mb-3 fw-bold lh-base">
                        Have a Social Media following?{" "}
                        <br className="hide-on-small-screen" /> Apply to become
                        our Founding Member and unlock benefits.
                      </div>
                      <div className="h2 fw-bold lh-sm mb-4">
                        {" "}
                        <span className="secondary-color">
                          Lead the Vibe
                        </span>{" "}
                        <br />
                        Become a Founding Member
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <AnimatedInput
                            name="partnerEmail"
                            type="email"
                            id="email-founder"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            value={partnerEmail}
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-6">
                          <AnimatedInput
                            name="partnerPhone"
                            type="tel"
                            id="phone-founder"
                            placeholder="Enter your phone number"
                            onChange={handleChange}
                            value={partnerPhone}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <AnimatedInput
                            name="partnerLinekdin"
                            type="url"
                            id="linkedin-founder"
                            placeholder="Enter your linkedin link"
                            onChange={handleChange}
                            value={partnerLinekdin}
                            className="form-control"
                          />
                        </div>
                        <div className="col-sm-6">
                          <AnimatedInput
                            name="partnerSocial"
                            type="url"
                            id="socialmedia-founder"
                            placeholder="Enter your social Media Link"
                            onChange={handleChange}
                            value={partnerSocial}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <button
                          className="btn btn-primary fw-bold px-5 founder-btn"
                          type="submit"
                          onClick={(e) => handleSubmit(e, 1)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="col-md-4 ">
                      <img src={founderGraphic} style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <footer className="d-flex flex-wrap align-items-center py-3 my-4 border-top custom-footer">
          <div className="col-md-4 d-flex align-items-center">
            <a
              href="/"
              className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
            >
              <svg className="bi" width="30" height="24"></svg>
            </a>
            <span className="text-muted">
              All Rights Reserved © {new Date().getFullYear()} by{" "}
              <span className="fw-bold">VibeTribe</span>
            </span>
          </div>

          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            {/* <li className="ms-3">
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </a>
            </li> */}
            <li className="ms-3">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/vibetribee_/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#962fbf"
                  className="bi bi-instagram"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg>
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default Landing;
